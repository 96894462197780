var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{},[_c('div',[_c('v-alert',{staticClass:"alert",class:{'alert-error':_vm.alertType=='error', 'alert-success':_vm.alertType=='success'},attrs:{"border":"left","dark":"","dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10 ml-auto mr-auto"},[_c('v-container',[_c('div',{staticClass:"mb-4 mt-8 d-flex"},[_c('div',{staticClass:"col-md-11"},[_c('h1',[_vm._v("Personnel Deductions")])]),_c('div',{staticClass:"col-md-1"},[_c('Button',{staticClass:"mr-2",attrs:{"btnType":'Submit',"label":'+'},on:{"onClick":_vm.addNew}})],1)]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"loading-text":"Fetching records... Please wait","headers":_vm.headers,"items":_vm.deductionList,"search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEmployeeName(item.employee))+" ")]}},{key:"item.effectiveDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.effectiveDate))+" ")]}},{key:"item.dedecutionAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.dedecutionAmount))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('Button',{staticClass:"mr-2",attrs:{"btnType":'Submit',"label":'Edit'},on:{"onClick":function($event){return _vm.edit(item)}}}),_c('Button',{staticClass:"mr-2",attrs:{"btnType":'Cancel',"label":'Delete'},on:{"onClick":function($event){return _vm.openCancel(item)}}})],1)]}}])}),_c('Dialog',{ref:"deleteModal",attrs:{"title":'Are you sure you want to delete this deduction',"width":420},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('Button',{staticClass:"mr-2",attrs:{"btnType":'Submit',"label":'Yes'},on:{"onClick":_vm.cancelRecord}}),_c('Button',{attrs:{"btnType":'Cancel',"label":'No'},on:{"onClick":function($event){return _vm.cancel('deleteModal')}}})]},proxy:true}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }